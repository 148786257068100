import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/container",
    meta:{
      requireAuth:false
    },
  },
  {
    path: "/container",
    name: "Container",
    component: () => import("../Layout/Container.vue"),
    redirect:'/container/show',
    children:[
      {
        path: "accesscontrol",
        name: "AccessControl",
        meta:{
          requireAuth:true,
          allow:['admin','logistics'],
        },
        component: () => import("../views/AccessControl.vue")
      },
      {
        path: "broadcast",
        name: "Broadcast",
        meta:{
          requireAuth:true,
          allow:['admin','logistics'],
        },
        component: () => import("../views/Broadcast.vue")
      },
      {
        path: "energyconsumption",
        name: "EnergyConsumption",
        meta:{
          requireAuth:true,
          allow:['admin','logistics'],
        },
        component: () => import("../views/EnergyConsumption.vue")
      },
      {
        path: "buildingautomation",
        name: "BuildingAutomation",
        meta:{
          requireAuth:true,
          allow:['admin','logistics'],
        },
        redirect:'/container/BuildingAutomation/co',
        component: () => import("../views/BuildingAutomation/index.vue"),
        children:[
          {
            path: "co",
            name: "CO",
            component: () => import("../views/BuildingAutomation/CO.vue")
          },
          {
            path: "water",
            name: "Water",
            component: () => import("../views/BuildingAutomation/Water.vue")
          },
          {
            path: "exhaustair",
            name: "ExhaustAir",
            component: () => import("../views/BuildingAutomation/ExhaustAir.vue")
          },
          {
            path: "lift",
            name: "Lift",
            component: () => import("../views/BuildingAutomation/Lift.vue")
          },
          {
            path: "airconditioning",
            name: "AirConditioning",
            component: () => import("../views/BuildingAutomation/AirConditioning.vue")
          },
          {
            path: "electricity",
            name: "Electricity",
            component: () => import("../views/BuildingAutomation/Electricity.vue")
          }
        ]
      },
      {
        path: "firecontrol",
        name: "FireControl",
        meta:{
          requireAuth:true,
          allow:['admin','logistics'],
        },
        component: () => import("../views/FireControl.vue")
      },
      {
        path: "illumination",
        name: "Illumination",
        meta:{
          requireAuth:true,
          allow:['admin','logistics'],
        },
        component: () => import("../views/Illumination.vue")
      },
      {
        path: "monitor",
        name: "Monitor",
        meta:{
          requireAuth:true,
          allow:['admin','logistics'],
        },
        component: () => import("../views/Monitor.vue")
      },
      {
        path: "teachingmanagement",
        name: "TeachingManagement",
        meta:{
          requireAuth:true,
          allow:['admin','departmentCenter'],
        },
        component: () => import("../views/TeachingManagement.vue")
      },
      {
        path: "msite",
        name: "Msite",
        meta:{
          requireAuth:true,
          allow:['admin','leadership','logistics','departmentCenter'],
        },
        component: () => import("../views/Msite.vue"),
      },
      {
        path: "show",
        name: "Show",
        meta:{
          requireAuth:true,
          allow:['admin','leadership','logistics','departmentCenter'],
        },
        component: () => import("../views/Show.vue")
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    redirect: "/container",
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// @ts-ignore
export default router;
