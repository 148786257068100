<template>
  <el-config-provider :locale="state.locale">
    <div class="app_container">
      <router-view />
    </div>
  </el-config-provider>
</template>
<script>
import { reactive,onMounted } from "vue";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
document.addEventListener('selectstart',function(e){
  var e = e || window.event; //兼容处理
  e.preventDefault();//阻止默认事件
});

export default {
  name: "App",
  data() {
    return {
      state: reactive({locale: zhCn,})
    }
  }
};
</script>
<style lang="less">
.app_container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>
