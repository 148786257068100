import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import "./css/reset.css";
import "./css/common.css";
import "./css/pop.less";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import axios from "axios";
// @ts-ignore
import HiConfig from "../src/HiConfig.js"

const app = createApp(App);
const singleSignOn = "https://cas.cqctcm.edu.cn/oauth2.0/authorize?response_type=code&client_id=lcUWF0RxO5nwdLVjUu&redirect_uri=https://10.32.102.170:13443/";

const dev = true  //true 为开发版   false 为生产版

if (dev) {
    sessionStorage.setItem("user_id", 'test');
    sessionStorage.setItem("user_role", 'admin');
    sessionStorage.setItem("remainderDate", '100');
    HiConfig.loadConfig("config.json").then(function () {
        app.use(ElementPlus, {
            locale: zhCn
        });
        app.use(router);
        app.mount("#app");
    })
} else {
    getRemainderDate();
    let herf = window.location.href;  //获取页面完整链接
    console.log('herf', herf)
    let start = herf.indexOf("code=");  //判断地址里面有无code
    if (start > -1) {
        let end = herf.lastIndexOf("com");
        let code = herf.slice(start + 5, end + 3);
        console.log("有code:   " + code);
        // http://127.0.0.1:
        axios.get("https://10.32.102.170:18095" + "/api/zyy/code" + `?code=${code}`).then(res => {
            console.log(res);
            if (res.data.status === "0") {
                console.log("登录成功!");
                sessionStorage.setItem("user_id", res.data.user_id);
                sessionStorage.setItem("user_role", 'admin');
                HiConfig.loadConfig("config.json").then(function () {
                    app.use(ElementPlus, {
                        locale: zhCn
                    });
                    app.use(router);
                    app.mount("#app");
                })
            } else {
                alert("登录信息失效！请重新登录");
            }
        }).catch(err => {
            console.log(err);
        });
    } else {
        //无code,跳转到统一登录页面
        // alert("无code,跳转到统一登录");
        console.log("无code,跳转到统一登录");
        window.location.replace(singleSignOn);
    }
}

function getRemainderDate() {
    return new Promise((resolve, reject) => {
        axios.get("https://10.32.102.170:18095" + "/api/system/license").then(res => {
            sessionStorage.setItem("remainderDate", res.data.msg_num);
            console.log(res.data.msg_num);
            resolve(res.data.msg_num);
        }).catch(err => {
            console.log(err);
        });
    });
}


//路由守卫
router.beforeEach((to, from, next) => {
    const remainderDate = sessionStorage.getItem("remainderDate");
    if (remainderDate) {
        goto(Number(remainderDate));
    } else {
        getRemainderDate().then((res) => {
            goto(Number(res));
        });
    }

    function goto(res: Number) {
        if (res > 0) {
            if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
                const user_role = sessionStorage.getItem("user_role");
                // @ts-ignore
                let i = to.meta.allow.findIndex(d => d === user_role)
                if (i > -1) {
                    next();
                } else {
                    alert("授权不足!");
                }
            } else {
                next();
            }
        } else {
            alert("授权期限不足!");
        }
    }
});

