/**
 * Created by sn on 2019/12/23.
 */

import axios from "axios";
function HiConfig () {

}

HiConfig.httpRoot = null

HiConfig.getHttpRoot = function () {
  if (!HiConfig.httpRoot) {
    //获取当前网址，如： http://localhost:8080/ems/Pages/Basic/Person.jsp
    var curWwwPath = window.document.location.href
    //获取主机地址之后的目录，如： /ems/Pages/Basic/Person.jsp
    var pathName = window.document.location.pathname.substring(1)
    var webName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/') + 1);
    HiConfig.httpRoot = HiConfig.getHttpHost() + webName
  }
  return HiConfig.httpRoot
}

HiConfig.getHttpHost = function () {
  return window.location.protocol + '//' + window.location.host + "/"
}

HiConfig.loadConfig = function (config_file) {
  let promise = new Promise(function(resove, reject) {
    let url = HiConfig.getHttpRoot() + config_file
    axios({
      method: "GET",
      url: url,
    }).then(response => {
      for (let key in response.data){
        HiConfig[key] = response.data[key]
      }
      resove()
    });
  })
  return promise
}
export default HiConfig
